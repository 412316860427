"use client";

import "@/app/firebase";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { getCookie } from "cookies-next";
import Loader from "@/app/utils/loading";
import useAuth from "@/app/dashboard/hooks/useAuth";
import { clearCookies } from "@/app/cookies/utils";

export default function Home() {
  const { user, loading } = useAuth();
  const router = useRouter();
  const accountId = getCookie("accountId");

  useEffect(() => {
    if (!accountId) {
      clearCookies();
    }
    if (!loading && !user) {
      return router.push("/sign-in");
    } else {
      return router.push("/dashboard");
    }
  }, [user, loading, router, accountId]);

  if (loading) {
    return <Loader />;
  }
}
