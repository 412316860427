"use client";

export function clearCookies() {
  document.cookie.split(";").forEach((cookie) => {
    const name = cookie.trim().split("=")[0];
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  });
}

export function setCookie(name: string, value: string) {
  document.cookie = `${name}=${value};path=/`;
}
